<template>
  <section>
    <div class="text">
      <div class="text--typed" ref="content"></div>
      <div class="text--hidden" v-html="body"></div>
    </div>
    <h5 v-if="data.field_zitat_von.length" class="subtitle-1 quotee">
      - {{ data.field_zitat_von[0].value }}
    </h5>
  </section>
</template>

<script>
import { httpMixin } from "@/mixins/httpMixin.js";
import Typed from "typed.js";
import ScrollMagic from "scrollmagic";

export default {
  mixins: [httpMixin],

  props: {
    data: Object
  },

  data: function() {
    return {
      animationController: new ScrollMagic.Controller()
    };
  },

  computed: {
    body: function() {
      return this.sanitise(this.data.body[0].value);
    }
  },

  mounted: function() {
    const self = this;
    new ScrollMagic.Scene({
      triggerElement: self.$refs.content,
      triggerHook: 1,
      reverse: false
    })
      .on("start", function() {
        new Typed(self.$refs.content, {
          strings: [self.body],
          showCursor: false,
          smartBackspace: false,
          typeSpeed: 22
        });
      })
      .addTo(this.animationController);
  }
};
</script>

<style scoped>
section {
  display: grid;
  padding: 120px 16px;
}

.text {
  font-family: "Roboto Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  place-self: center;
  position: relative;
}

.text--hidden {
  visibility: hidden;
}

.text--typed {
  position: absolute;
}

.quotee {
  place-self: end;
}

@media screen and (min-width: 450px) {
  section {
    padding: 120px 60px;
  }

  .text {
    font-size: 3rem;
  }
}

@media screen and (min-width: 768px) {
  section {
    padding-left: 180px;
    padding-right: 180px;
  }

  .text {
    font-size: 6rem;
  }
}
</style>
